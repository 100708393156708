body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /*background: linear-gradient(135deg, #0b1b2c, #4a9b94,rgb(141, 114, 65));*/
  background-color: rgb(228, 228, 228);
  color: rgb(98, 98, 98);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainDisplay{
  width: 80%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_container {
  display: flex;
  width: 100%;
  margin-left: 50%;
  justify-content: space-between;
  gap: 30px;
  font-family: 'Poppins';
}



/* navbar */
.navbarapp {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 59px;
  height: 97vh;
  padding: 10px;
  border-radius: 25px;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s;
}

.navbarapp:hover{
  width: 256px;
}

.userNav {
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f6f6f6;
  
}

.positionInfo{
  font-weight: bold;
  font-size: 12px;
  color: rgb(130, 130, 130);
  font-family: 'Poppins';
  text-transform: uppercase;
  text-wrap:nowrap;
}

.nameInfo{
  font-size: 10px;
  font-family: 'Poppins';
  text-wrap:nowrap;
}

.logout_but{
  font-size: 10px;
  font-family: 'Poppins';
  text-decoration:underline;
  cursor: pointer;
}

.mainNav ul{
  list-style-type: none;
}


.mainNav ul li a {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  gap: 18px;
  color: rgb(144, 144, 144);
  border-radius: 5px;
  text-decoration: none;
  align-content: center;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  text-wrap:nowrap;
  transition: all 0.3s;
}

.subicon{
  font-size: 15px;
  align-self:center;
}

#arrowDown{
  font-size: 18px;
  justify-self: start;
  align-self:center;
}



.subNav{
  
  display: none;
  
  
}



.subNav ul li a span{
  justify-self: left;
}

.activeSub{
  background-color: #fdfdfd;
  border: 1px solid rgb(223, 240, 235);
  border-radius: 5px;
  
  
}


.activeSub ul li a {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  gap: 20px;
  color: rgb(144, 144, 144);
  border-radius: 5px;
  text-decoration: none;
  align-content: center;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  text-wrap:nowrap;
  transition: all 0.3s;
  
}

#arrowDown{
  transition:ease-out 0.3s;
}

#arrowDownActive{
  transform: rotate(90deg);
  scale: 0.72;
  transition: ease-out 0.3s;
}


.mainNav ul li.activeBut > a {
  color: rgb(175, 209, 199);
  
}



.mainNav ul li a:hover {
  background-color: rgb(248, 248, 248);
  color: rgb(114, 114, 114);
}


.logo{
  font-family: 'Edu AU VIC WA NT Hand';
  font-size: 10px;
  padding-top: 5px;
  padding-left: 10px;
}


/* login */
.loginform,.signupform {
  width: 75%;
  background-color: rgba(255, 255, 255, 0.612);
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.material-symbols-outlined {
  font-size: 80px;
  color:rgb(98, 98, 98);
}
.form_head {
  
  display:flex;
  justify-content: center;
  align-items:baseline;
  padding-top: 20px;
  
}

.signuphead {
  display:flex;
  justify-content: center;
  align-items:center;
}

.signuphead p {
  font-size: 15px;
  background-color: rgb(231, 158, 90);
  color: #ffffff;
  padding: 5px;
  border-radius: 10px 5px 5px 0;
  margin-bottom: 20px;
}


.form_logo{
  font-family: 'Edu AU VIC WA NT Hand';
  font-size: 30px;
  
  
}

.app_ver{
  font-family: 'Roboto Mono';
  font-size: 10px;
  padding-left: 5px;
  

}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 24
}


input[type="email"], input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-family: 'Poppins';
}




input[type="email"]:focus-visible, input[type="password"]:focus-visible {
  outline: none;
}

.Log_button {
  display: flex;
  margin-top: 10px;
}

.Sign_button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 30px;
}
.Sign_button a {
  font-size: 12px;
  align-self: center;
  text-decoration: none;
  color: rgb(227, 200, 175);
  cursor: pointer;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Log_btn {
  background-color: #347999;
  color: white;
  font-family: 'Poppins';
  
}

.Sign_btn {
  background-color: rgb(231, 158, 90);
  color: #ffffff;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
}

/*Info*/

.info {
  width: 50%;
}

.info h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.info p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.info a {
  color: #daae75;
  text-decoration: none;
  font-weight: bold;
  border: #daae75 2px solid;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

/*Alert_msg*/

.msgTab {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 25px;
}

.msgAlert::before{
  content: "! ";
}

.msgAlert {
  display: inline;
  background-color: rgb(104, 44, 44);
  border-radius: 20px 20px 20px 20px ;
  font-size: 16px;
  color: rgb(220, 207, 207);
  padding: 0 10px 0 10px;
  text-align: center;
}

.user_input_alert{
  background-color: rgb(255, 255, 255);
  outline: rgb(161, 52, 52) 2px solid;
}

.user_input_alert:focus-visible{
  background-color: rgb(255, 255, 255);
  
}

.user_input_alert::placeholder{
  color: rgb(161, 52, 52);
  font-weight: bold;
}

/*Logout Dialog*/
.logoutDialog{
  padding: 10px 20px 20px 20px;
  font-family: "Poppins";
  color: rgb(66, 66, 66);
}

.closeDialogButton{
 
  display: flex;
  justify-content:flex-end;
  align-content: flex-end;
}

.closeButton{
  color: rgb(132, 61, 61);
  
  display:inline-flex;
  text-align: left;
  cursor: pointer;
}

.logoutDialogButton{
  display: flex;
  justify-content: center;
  
}

.logoutDialogButton a{
  padding: 5px 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  background-color:  rgb(175, 209, 204);
  border-radius: 10px ;
  cursor: pointer;
}

.logoutDialogButton a:hover{
  background-color:  rgb(201, 231, 222);
  
}

/*Select Site Dialog*/

.selectSiteDialog{
  padding: 25px;
  font-family: "Poppins";
  text-transform: uppercase;
  color: rgb(66, 66, 66);
  min-width: 500px;
  
}

.selectSiteDialog h3{
  
  font-family: "Poppins";
  text-transform: uppercase;
  color: rgb(66, 66, 66);
}

.selectSiteDialog p{
  
  font-family: "Poppins";
  text-transform: uppercase;
  color: rgb(66, 66, 66);
}

/*Home*/
.homeSite{
  position:absolute;
  display:inline-block;
  width: 100%;
  top: 10px;
}


.homeSitecard{
  gap:10px;
  display:flex;
  background-color:  rgb(245, 245, 245);
  border-radius: 10px ;
  min-width: 400px;
  height:120px;
  padding: 10px 10px;
  font-family: "Poppins";
  text-transform: uppercase;
}

.homeSitecontent{
  display:flex;
  flex-direction: column;
  gap: 0;
}

.homeSitecontent h1{
  border-bottom: 1px solid rgb(227, 227, 227);
}

.homeSitecontent p{
  color: rgb(127, 127, 127);
}

.homeheader {
  position:absolute;
  
  display:flex;
  gap: 20px;
  width: 96%;
  left:80px;
  
}

.Unbilledcard{
  background-color:rgb(241, 161, 161) ;
  min-width: 200px;
  height: 200px;
  border-radius: 10px ;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
  flex-grow: 1;
  text-transform: uppercase;
}

.Plancard {
  background-color:rgb(166, 192, 232) ;
  min-width: 200px;
  height: 200px;
  border-radius: 10px ;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
  flex-grow: 1;
  text-transform: uppercase;
}

.Planvalue{
  
  padding-right: 10px;
  justify-self: right;
  font-size: 35px;
}

.Progresscard{
  background-color:rgb(156, 232, 197) ;
  min-width: 200px;
  height: 200px;
  border-radius: 10px ;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
  flex-grow: 1;
  text-transform: uppercase;
}

.Paymentcard{
  background-color:rgb(238, 211, 136) ;
  min-width: 200px;
  height: 200px;
  border-radius: 10px ;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
  flex-grow: 1;
  text-transform: uppercase;
}


.Plancontent, .Progresscontent,.Paymentcontent,.Unbilledcontent {
  padding: 5px 15px;
}

.homebody{
  display: inline-flex;
  position: absolute;
  top: 220px;
  left:80px;
  width: 96%;
  height: 700px;
  gap: 20px;
}

.upcomingEvent{
  display: flex;
  transform: translateY(-80px);
  border-radius: 10px ;
  background-color:rgb(245, 245, 245) ;
  min-width: 400px;
  height: 780px;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
  justify-content: center;
}

.chart{
  display: flex;
  border-radius: 10px ;
  background-color:rgb(245, 245, 245) ;
  width: 1500px;
  min-width: 1000px;
  flex: 1;
  height: 700px;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
}

.future{
  display: flex;
  border-radius: 10px ;
  background-color:rgb(245, 245, 245) ;
  width: 500px;
  height: 700px;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
}

.chartBox{
  width: 100%;
  font-family: "Poppins";
}

.chartShow{
  width: 100%;
  
  padding: 25px;
}

/*mr*/
.mrScreen{
  position:absolute;
  width: 100%;
  top: 10px; 
}

.mrhead{
  
  display:flex;
  justify-content: right;
  gap: 20px;
  height:50px;
  width: 96%;
  left:80px;
}

.controlBut{
  display:flex;
  gap: 15px;
  height: fit-content;
}

.newmrBut{
  display:flex;
  gap:2px;
  align-content: center;
  color: #ffffff;
  background-color: rgb(154, 224, 126);
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
}

.newmrBut:hover{
  background-color: rgb(168, 213, 151);
}

.setappBut{
  display:flex;
  gap:2px;
  align-content: center;
  color: #ffffff;
  background-color: rgb(224, 175, 126);
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 5px 5px 10px rgb(218, 218, 218);
}

.setappBut:hover{
  background-color: rgb(233, 190, 153);
}

.mkrnewmrDia{
  text-transform: uppercase;
  font-family: "Poppins";
  padding: 20px;
  width: 1500px;
}

.selectionDep{
  min-width:120px;
  
}

.toselection{
  display: flex;
  align-items: center;
  height: 60px;
  
  color: #3c3b3b;
}

.mrno{
  display: flex;
  align-items: center;
  height: 60px;
  
  color: #3c3b3b;
  font-size: 20px;
  font-weight: bold;
  color: #3c3b3b;
  gap: 30px;
}

.mrno1 {
  display: flex;
}

.mrno1 input{
  width: 75px;
  height: 30px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: bold;
  color: #000000;
  background-color: #ffe4c0;
  font-family: "Poppins";
  
  padding-top: 2px;
  padding-left: 2px;
}

.mrno1 input:focus{
  outline: none;
}

#mrdialog{
 display:inline-table;
 justify-self: center;
  
}

textarea{
  resize: none;
}

br{
  display: none;
}
.mrinfo{
  display: flex;
  gap:30px;
}
.mrfromtext{
  font-size: 20px;
  font-weight: bold;
}
.mrtotext{
  font-size: 20px;
  font-weight: bold;
  margin-left: 50px;
}

.selectionDep select{
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  height: 30px;
  margin-left: 10px;
  text-transform: uppercase;
  font-family: "Poppins";
  background-color: #c0ffd6;
}

.selectionDep select:focus-visible{
  outline: none;
}

.selectionDep input{
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  height: 30px;
  margin-left: 10px;
  text-transform: uppercase;
  font-family: "Poppins";
  width: 150px;
  padding-left: 5px;
}

.mrreqtext{
  font-size: 20px;
  font-weight: bold;
  margin-left: 50px;
}

.selectionReq{
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-datefield .MuiInputBase-root {
  height: 30px!important;
  font-size: 16px!important;
  width: 121px!important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  overflow: hidden !important;
  padding: 0 0 0 0 !important;
  box-sizing: border-box !important;
  text-align: center !important;
  font-family: "Poppins";
}

.custom-datefield .MuiOutlinedInput-notchedOutline {
  border-radius: 5px!important;
}


.custom-date .MuiInputBase-root {
  height: 30px!important;
  font-size: 16px!important;
  width: 170px!important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  overflow: hidden !important;
  padding: 0 5px 0 0 !important;
  box-sizing: border-box !important;
  text-align: center !important;
  font-family: "Poppins";
}

.custom-date .MuiOutlinedInput-notchedOutline {
  border-radius: 5px!important;
}







.tabulator-edit-list {
  all: unset; /* รีเซ็ตสไตล์ทั้งหมด */
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  color: #000 !important;
  z-index: 1301 !important;
  position: absolute !important;
  max-height: 200px; /* กำหนดความสูงสูงสุดของ dropdown (200px ประมาณ 10 รายการ) */
  overflow-y: auto; 
  font-family: "Poppins";
  text-transform: uppercase;
  font-size: 14px;
}

.tabulator-edit-list-item:hover {
  background-color: #f0f0f0 !important; /* เพิ่มสีพื้นหลังเมื่อ hover */
  cursor: pointer!important;
}

.tabulator-edit-list::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1; /* พื้นหลัง scrollbar */
}

.tabulator-edit-list::-webkit-scrollbar-thumb {
  background-color: #888; /* สี scrollbar */
  border-radius: 4px;
}

.tabulator-edit-list::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* สี scrollbar เมื่อ hover */
}

